#custCarousel {
  overflow: hidden;
  width: 100%;

  .carousel-inner img {
    width: 100%;
    height: 100%
  }

  .carousel-indicators {
    position: relative;
    margin: 0;
    transform: translateY(-50%);
    z-index: 0;

    li {
      width: 100px;
      height: 5rem;
      border: 4px solid var(--toc-grey);

      img {
        display: block;
        object-fit: cover;
        height: 5rem;
      }

      &:hover {
        img {
          opacity: 0.75
        }
      }
    }
  }

  @media (max-width: 576px) {
    .carousel-indicators {
      li {
        img {
          height: 4rem;
        }
      }
    }

    .carousel-inner img {
      height: 40vh;
      object-fit: cover;
    }
  }
}
